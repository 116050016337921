<template>
  <div class="login">
    <div class="login__container">
      <div class="login__container--left">

        <img
          src="@/assets/wifi.svg"
          alt="wifi icon"
          class="wifi-icon"
        >
      </div>
      <div class="login__container--right">
        <img
          :src="getImageUrl(`branding/${getCompanyLogo()}`)"
          alt="aljay logo"
          class="aljay-logo"
        >
        <h1>Wifi Voucher Generator</h1>
        <h2>Sign In!</h2>
        <ValidationObserver
          ref="form"
          v-slot="{ handleSubmit }"
        >

          <form
            novalidate
            @submit.prevent="handleSubmit(onFormPostSubmit)"
          >
            <ValidationProvider
              #default="{ errors }"
              vid="email"
              name="email"
              rules="required|email"
            >
              <b-form-group>
                <label for="emailInput">Email</label>
                <input
                  id="emailInput"
                  v-model="credentials.email"
                  type="email"
                  :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email"
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              #default="{ errors }"
              vid="password"
              name="password"
              rules="required"
            >
              <b-form-group>
                <label for="passwordInput">Password</label>
                <input
                  id="passwordInput"
                  v-model="credentials.password"
                  type="password"
                  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                  placeholder="Enter Password"
                  required
                  :disabled="isBusy"
                  autocomplete="off"
                >
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <div
              class="d-inline-block w-100"
              style="margin-top: 1rem;"
            >
              <b-button
                type="submit"
                variant="success"
                block
                :disabled="isBusy"
                style="border-radius:50px"
              >
                Sign in
              </b-button>
            </div>
            <div class="login-with">
              <div />
              <p>or sign in with</p>
              <div />
            </div>

            <b-button
              type="button"
              variant="secondary"
              block
              :disabled="isBusy"
              style="border-radius:50px"
              @click="useAuthProvider('oauth2', Oauth2)"
            >
              Aljay SSO
            </b-button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedAuthService, SharedSSOService } from '@/services'
import { Oauth2 } from 'universal-social-auth'
import url from '@/mixins/url'
import misc from '@/mixins/misc'

export default {
  name: 'SharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  mixins: [url, misc],

  data: () => ({
    isBusy: false,
    credentials: {
      email: '',
      password: ''
    },
    Oauth2
  }),

  mounted () {
    core.index()
  },

  methods: {
    useAuthProvider (provider, proData) {
      this.$Oauth
        .authenticate(provider, proData)
        .then(data => {
          this.useSocialLogin(data)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async useSocialLogin (code) {
      await SharedSSOService.post(code)
        .then(response => {
          this.$store.dispatch('auth/saveToken', response.data.token)

          SharedAuthService.FetchUser()
            .then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)

              let routeName = 'dashboard'

              const user = this.$store.getters['auth/user']

              if (user) {
                routeName = `${user.role}.dashboard`
              }

              this.$router.replace({ name: routeName })
            })
            .catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })
        })
        .catch(err => {
          console.log({ err: err })
        })
    },

    async onFormPostSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.isBusy = true
          SharedAuthService.SignIn(this.credentials).then(response => {
            this.$store.dispatch('auth/saveToken', response.data.token)

            SharedAuthService.FetchUser().then(({ data }) => {
              this.$store.dispatch('auth/fetchUserSuccess', data)

              let routeName = 'dashboard'

              const user = this.$store.getters['auth/user']

              if (user) {
                routeName = `${user.role}.dashboard`
              }

              this.$router.replace({ name: routeName })
            }).catch(() => {
              this.$store.dispatch('auth/fetchUserFailure')
            })

            this.isBusy = false
          }).catch(error => {
            this.isBusy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
              }
            }
          })
        }
      })
    }
  }
}
</script>

<style  lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: #edf2f4;
  display: flex;
  align-self: center;

  &__container {
    display: grid;
    align-self: center;
    grid-template-columns: repeat(2, 1fr);
    max-width: 992px;
    background: white;
    margin: 0 auto;
    box-shadow: -1px 3px 63px -3px rgba(227,219,219,0.64);
    -webkit-box-shadow: -1px 3px 63px -3px rgba(227,219,219,0.64);
    -moz-box-shadow: -1px 3px 63px -3px rgba(227,219,219,0.64);

    &--left {
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #f5f6f5;

      .wifi-icon {
        max-width: 80%;
      }
    }

    &--right {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: space-around;
      .aljay-logo {
        height: 60px;
        max-width: 100%;
        align-self:center;
      }
      h1, h2{
        line-height: 1.2;
      }
      h1 {
        color: #121312;
        font-size: 26px;
        font-weight: 600;
        text-align: center;

      }
      h2 {
         color: #474847;
        font-size: 18px;
        font-weight: 400;
      }
      .login-with {
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
          div {
            height: 2px;
            border: 1px solid #f5f6f5;
            background: #f5f6f5;
          }
          p {
            margin: 0 auto;
            padding: 0 0.5rem;
          }
      }
    }

  }
}

@media screen and (max-width: 992px) {
  .login {
    &__container {
      max-width: 740px;
      &--left {
        .wifi-icon {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    &__container {
      width: 100%;
      margin: 0 2rem;
      grid-template-columns: 1fr;
      &--left {
        display: none;
      }

    }
  }
}
@media screen and (max-width: 567px) {
  .login {
    &__container {
      width: 100%;
      margin: 0 2rem;
      grid-template-columns: 1fr;

    }
  }
}

</style>
